export default (function(){
    function getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
    
        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'mac-os';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'i-os';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'windows-os';
        } else if (/Android/.test(userAgent)) {
            os = 'android-os';
        } else if (!os && /Linux/.test(platform)) {
            os = 'linux-os';
        }
    
        return os;
    }
    
    document.body.classList.add(getOS());
})();