import { BaseForm, NeonForm } from './MailForm';
import "imask";

export default (function(){
    function checkRequiredInputEvent(event){
        if (typeof event.target.value == 'string' && event.target.value != ''){
            event.target.classList.remove('invalid');
        } else {
            event.target.classList.add('invalid');
        }
    }

    function delay(ms){
        return new Promise((resolve, reject) => {
            setTimeout(resolve, ms);
        });
    }

    document.addEventListener('DOMContentLoaded', function(){
        const requiredInputs    = document.querySelectorAll('.formInput input[required]');
        const phoneInputs       = document.querySelectorAll('input[type="tel"]');
        const neonForm1Element   = document.querySelector('#neon1');
        const neonForm2Element   = document.querySelector('#neon2');
        const contactusFormElement   = document.querySelector('#formContact');
        
        const neonAnimation1 = [
            {
                selector: '.neonMail1-p1',
                delay: 0,
                styles: {
                    strokeOpacity: 1
                }
            },
            {
                selector: '.neonMail1-p2',
                delay: 1000,
                styles: {
                    strokeOpacity: 1
                }
            },
            {
                selector: '.neonMail1-p3',
                delay: 2000,
                styles: {
                    strokeOpacity: 1
                }
            },
            {
                selector: '.neonMail1-p4',
                delay: 3000,
                styles: {
                    strokeOpacity: 1
                }
            }
        ];

        const neonAnimation2 = [
            {
                selector: '.neonMail2-p1',
                delay: 0,
                styles: {
                    strokeOpacity: 1
                }
            },
            {
                selector: '.neonMail2-p2',
                delay: 1000,
                styles: {
                    strokeOpacity: 1
                }
            },
            {
                selector: '.neonMail2-p3',
                delay: 2000,
                styles: {
                    strokeOpacity: 1
                }
            },
            {
                selector: '.neonMail2-p4',
                delay: 3000,
                styles: {
                    strokeOpacity: 1
                }
            },
            {
                selector: '.neonMail2-p5',
                delay: 4000,
                styles: {
                    strokeOpacity: 1
                }
            }
        ]

        const form1 = new NeonForm(neonForm1Element, neonAnimation1);
        const form2 = new NeonForm(neonForm2Element, neonAnimation2);
        const form3 = new BaseForm(contactusFormElement);
        
        const maskOptions = {
            mask: '+{7}(000)000-00-00'
        };
        
        requiredInputs.forEach(input => {
            input.addEventListener('input', checkRequiredInputEvent);
            input.addEventListener('change', checkRequiredInputEvent);
            input.addEventListener('blur', checkRequiredInputEvent);
        });
        
        phoneInputs.forEach(input => {
            IMask(input, maskOptions);
        });
    });

    return {
        NeonForm
    }
})();