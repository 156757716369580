export default class Tabs {
    constructor(selector, isAccordion){
        if (typeof isAccordion == 'boolean'){
            this.isAccordion = isAccordion;
        } else {
            this.isAccordion = false;
        }
        
        this._selector = selector;
        this._tabsElement = document.querySelector(selector);
        this._linkElements = this._tabsElement.querySelectorAll('[data-tabs="link"]');
        
        this.tabs = [];
        this.size = 'full';
        this.minHeight = 0;

        this._linkElements.forEach(link => {
            const tab = {};
            
            tab.link = link;
            tab.selector = link.getAttribute('href');
            tab.element = this._tabsElement.querySelector(tab.selector);
            tab.active = tab.element.classList.contains('active');
            tab.content = tab.element.querySelector('[data-tabs="content"]');

            // tab.link.addEventListener('mouseover', e => {
            //     e.preventDefault();
            //     if (this.size == 'full' && !this.isAccordion){
            //         this.select({selector: tab.selector});
            //     }
            // });
            
            tab.link.addEventListener('click', e => {
                e.preventDefault();
                this.select({selector: tab.selector});
            })
            
            this.tabs.push(tab);
        });

        setInterval(() => {
            if (this.size == 'full' && !this.isAccordion){
                if (this._tabsElement.offsetHeight != this._getMaxTabHeight()){
                    this.update();
                }
            }
        }, 1000);

        window.addEventListener('resize', () => {
            this.resize();
        });

        this._getScreenSize();
    }
    
    select({selector}){
        if (this.size == 'small') {
            if (this.tabs.find(tab => tab.selector == selector).active){
                this.tabs.forEach(tab => tab.active = false);
            } else {
                this.tabs.forEach(tab => tab.active = false);
                this.tabs.find(tab => tab.selector == selector).active = true;

                setTimeout(() => {
                    this.tabs.find(tab => tab.selector == selector).element.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth'
                    });
                }, 150);
            }
        } else {
            
            if (this.tabs.find(tab => tab.selector == selector).active){
                this.tabs.forEach(tab => tab.active = false);
            } else {
                this.tabs.forEach(tab => tab.active = false);
                this.tabs.find(tab => tab.selector == selector).active = true;
            }
        }

        this.update();
    }

    resize(){
        this._getScreenSize();
        this.update();
    }

    _getScreenSize(){
        if (window.innerWidth > 767) {
            this.size = 'full';
        } else {
            this.size = 'small';
        }
    }

    update(){
        if (this.isAccordion){
            this._updateSmall();
        } else {
            if (this.size == 'full'){
                this._updateFull();
            } else {
                this._updateSmall();
            }
        }
    }
    
    _updateFull(){
        if (this.tabs.some(tab => tab.active)) {
            this.tabs.forEach(tab => tab.element.classList.remove('active'));
            this.tabs.find(tab => tab.active).element.classList.add('active');
        } else {
            this.tabs[0].element.classList.add('active');
        }
        
        this.minHeight = this._getMaxTabHeight();
        this._tabsElement.style.minHeight = `${this.minHeight}px`;
    }

    _updateSmall(){
        if (this.tabs.some(tab => tab.active)){
            this.tabs.forEach(tab => {
                tab.element.classList.remove('active');
                tab.content.style.height = 0;
            }); 
            
            const activeTab = this.tabs.find(tab => tab.active);
            activeTab.element.classList.add('active');
            activeTab.content.style.height = `${activeTab.content.querySelector('[data-tabs="inner"]').scrollHeight}px`;
        } else {
            this.tabs.forEach(tab => {
                tab.element.classList.remove('active');
                tab.content.style.height = 0;
            });
    
        }

        this.minHeight = 0;
        this._tabsElement.style.minHeight = `${this.minHeight}px`;
    }

    _getMaxTabHeight(){
        let maxHeight = 0;
    
        this.tabs.forEach(tab => {
            if (tab.content.offsetHeight > maxHeight) {
                maxHeight = tab.content.offsetHeight;
            }
        });

        return maxHeight;
    }
}