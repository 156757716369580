import innerHeight from 'ios-inner-height';

export class Menu{
    constructor({
        container,
        buttonOpen,
        buttonClose
    }){
        this.container = container;
        this.buttonOpen = buttonOpen;
        this.buttonClose = buttonClose;
        this.isOpen = false;
    }

    init(){
        this._containerElement = document.querySelector(this.container);
        this._buttonOpenElement = document.querySelector(this.buttonOpen);
        this._buttonCloseElement = document.querySelector(this.buttonClose);
        this._footer = document.querySelector('.footer');

        this.isOpen = this._containerElement.classList.contains('open');

        this._buttonOpenElement.addEventListener('click', e => {
            e.preventDefault();
            this.isOpen = true;

            this.update();
        });

        this._buttonCloseElement.addEventListener('click', e => {
            e.preventDefault();
            this.isOpen = false;

            this.update();
        });

        window.addEventListener('scroll', e => {
            const buttonOpenOffset = window.innerHeight - this._buttonOpenElement.offsetTop - this._buttonOpenElement.offsetHeight / 2;

            if (window.scrollY > buttonOpenOffset){
                this._buttonOpenElement.classList.add('black');
            } else {
                this._buttonOpenElement.classList.remove('black');
            }
        });

        document.querySelectorAll('.menu-item > a').forEach(menuItem => {
            if (!menuItem.closest('.menu-item').classList.contains('menuGrid-instagram')) {
                menuItem.addEventListener('click', e => {
                    e.preventDefault();
    
                    this.isOpen = false;
                    document.querySelector(e.target.getAttribute('href')).scrollIntoView({behavior: "smooth"});
                    this.update();
                });
            }
        });
        document.querySelectorAll('.footer-item > a').forEach(menuItem => {
            if (!menuItem.closest('.footer-item').classList.contains('menuGrid-instagram')) {
                menuItem.addEventListener('click', e => {
                    e.preventDefault();
    
                    this.isOpen = false;
                    document.querySelector(e.target.getAttribute('href')).scrollIntoView({behavior: "smooth"});
                    this.update();
                });
            }
        });
    }

    update(){
        if (this.isOpen) {
            this._containerElement.classList.add('open');
            document.body.classList.add('menu-open');
            document.getElementsByTagName('html')[0].classList.add('menu-open');
        } else {
            this._containerElement.classList.remove('open');
            document.body.classList.remove('menu-open');
            document.getElementsByTagName('html')[0].classList.remove('menu-open');
        }
    }
}

export class MenuCall{
    constructor(selector){
        this.selector = selector;
    }

    init(){
        this._buttonElement = document.querySelector(this.selector);

        window.addEventListener('scroll', e => {
            const buttonOffset = window.innerHeight - this._buttonElement.offsetTop - this._buttonElement.offsetHeight / 2;

            if (window.scrollY > buttonOffset) {
                this._buttonElement.classList.add('black');
            } else {
                this._buttonElement.classList.remove('black');
            }
        });
    }
}