import Swiper from 'swiper/swiper-bundle';

export default (function(){
    document.addEventListener('DOMContentLoaded', function() {
        const items = document.querySelectorAll('.actionsItem-open');
        const itemsReadmore = document.querySelectorAll('.actionsItem-readmore > a');
        let swiper;

        if (document.querySelector('.actionsSlideshow')) {
            window.addEventListener('scroll', () => {
                if (typeof swiper == 'undefined' && (window.scrollY + window.innerHeight > document.querySelector('.actionsSlideshow').offsetTop)){
                    swiper = new Swiper('.actionsSlideshow', {
                        lazy: true,
                        preloadImages: false,
                        slidesPerView: 'auto',
                        spaceBetween: 10,
                        watchSlidesVisibility: 3,
                        breakpoints: {
                            576: {
                                spaceBetween: 10,
                            },
                            768: {
                                spaceBetween: 10,
                            },
                            992: {
                                spaceBetween: 40,
                            }
                        },
                        pagination: {
                            el: '.actionsSlideshow-pagination',
                            type: 'fraction',
                            renderFraction: function (currentClass, totalClass) {
                                return `<div class="sliderInfo">` +
                                        `   <span class="sliderInfo-index ${currentClass}"></span>` +
                                        `   <span class="sliderInfo-sep"></span>` +
                                        `   <span class="sliderInfo-count ${totalClass}"></span>` +
                                        `</div>`;
                            },
                        },
                        navigation: {
                            nextEl: '.actionsSlideshow-next',
                            prevEl: '.actionsSlideshow-prev',
                        },
                    });
                }
            });
        }



        items.forEach(slide => {
            slide.addEventListener('click', function(e) {
                e.preventDefault();

                let index = this.getAttribute('data-slide');

                actionTextOpen(index);

                if (swiper.snapGrid.length < index){
                    index = swiper.snapGrid.length
                }

                swiper.slideTo(index - 1);
            });
        });

        itemsReadmore.forEach(readmore => {
            readmore.addEventListener('click', function(e) {
                e.preventDefault();

                const index = this.getAttribute('data-slide');

                if(e.target.classList.contains('open')){
                    actionTextClose();
                } else {
                    actionTextOpen(index);
                }
            })
        })

        function actionTextOpen(index){
            actionTextClose();

            const readmore = document.querySelector(`.actionsItem-readmore > a[data-slide="${index}"]`);
            readmore.classList.add('open');
            readmore.innerHTML = `<span class="icon-arrow-left"></span>Назад`;

            const textElement = document.querySelector(`.actionsItem-text[data-slide-text="${index}"]`);
            textElement.style.height = `${textElement.querySelector('.actionsItem-textInner').offsetHeight}px`;
        }

        function actionTextClose(){
            document.querySelectorAll(`.actionsItem-text`).forEach(item => {
                item.style.height = 0;
            })

            itemsReadmore.forEach(readmore => {
                readmore.classList.remove('open');
                readmore.innerHTML = `Подробнее <span class="icon-arrow-right"></span>`;
            })
        }
    });
})();