import CanvasSlider from '@martsis/canvas-slider/src/canvas-slider';
import Swiper from 'swiper/swiper-bundle';

let colorSwiper;
let colorSlider;
let shapeSwiper;
let shapeSlider;
let individualitySwiper;
let individualitySlider;

const colorSliderParams = {
    container: '#colorSlideshow',
    width: 680,
    height: 880,
    lazy: true,
    pattern: {
        width: 320,
        height: 320,
        blur: 10
    },
    onNext: () => {
        if (window.innerWidth >= 768){
            colorSwiper.slideNext();
        }
    },
    onPrev: () => {
        if (window.innerWidth >= 768){
            colorSwiper.slidePrev();
        }
    }
}

const shapeSliderParams = {
    container: '#shapeSlideshow',
    width: 680,
    height: 880,
    lazy: true,
    pattern: {
        width: 320,
        height: 320,
        blur: 10
    },
    onNext: () => {
        if (window.innerWidth >= 768){
            shapeSwiper.slideNext();
        }
    },
    onPrev: () => {
        if (window.innerWidth >= 768){
            shapeSwiper.slidePrev();
        }
    }
}

const individualitySliderParams = {
    container: '#individualitySlideshow',
    width: 680,
    height: 880,
    lazy: true,
    pattern: {
        width: 320,
        height: 320,
        blur: 10
    },
    onNext: () => {
        if (window.innerWidth >= 768){
            individualitySwiper.slideNext();
        }
    },
    onPrev: () => {
        if (window.innerWidth >= 768){
            individualitySwiper.slidePrev();
        }
    }
}

document.addEventListener('DOMContentLoaded', function(){
    colorInit();
    shapeInit();
    individualInit();

    window.addEventListener('scroll', () => {
        colorInit();
        shapeInit();
        individualInit();
    });
});

function colorInit(){
    if (typeof colorSlider == 'undefined' && (window.scrollY + window.innerHeight >= document.querySelector('#colorSlideshow').offsetTop)){
        colorSwiper = new Swiper('.colorSlideshow', {
            slidesPerView: 1,
            loop: false,
            pagination: {
                el: '.colorSlideshow-pagination',
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return `<div class="sliderInfo">` +
                            `   <span class="sliderInfo-index ${currentClass}"></span>` +
                            `   <span class="sliderInfo-sep"></span>` +
                            `   <span class="sliderInfo-count ${totalClass}"></span>` +
                            `</div>`;
                },
            },
            breakpoints: {
                768: {
                    simulateTouch: false,
                    shortSwipes: false,
                    longSwipes: false,
                    followFinger: false,
                }
            },
            on: {
                slideNextTransitionStart: function(){
                    if (colorSlider && window.innerWidth < 768){
                        colorSlider.next();
                    }
                },
                slidePrevTransitionStart: function(){
                    if (colorSlider && window.innerWidth < 768){
                        colorSlider.prev();
                    }
                }
            },
        });
        colorSlider = new CanvasSlider(colorSliderParams);
        document.querySelector('.colorSlideshow-next').addEventListener('click', e => {
            if (window.innerWidth >= 768){
                colorSlider.next()
            } else {
                colorSwiper.slideNext()
            }
        });

        document.querySelector('.colorSlideshow-prev').addEventListener('click', e => {
            if (window.innerWidth >= 768){
                colorSlider.prev()
            } else {
                colorSwiper.slidePrev()
            }
        });
    }
}

function shapeInit(){
    if (typeof shapeSlider == 'undefined' && (window.scrollY + window.innerHeight >= document.querySelector('#shapeSlideshow').offsetTop)){
        shapeSwiper = new Swiper('.shapeSlideshow', {
            slidesPerView: 1,
            loop: false,
            pagination: {
                el: '.shapeSlideshow-pagination',
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return `<div class="sliderInfo">` +
                            `   <span class="sliderInfo-index ${currentClass}"></span>` +
                            `   <span class="sliderInfo-sep"></span>` +
                            `   <span class="sliderInfo-count ${totalClass}"></span>` +
                            `</div>`;
                },
            },
            breakpoints: {
                768: {
                    simulateTouch: false,
                    shortSwipes: false,
                    longSwipes: false,
                    followFinger: false,
                }
            },
            on: {
                slideNextTransitionStart: function(){
                    if (shapeSlider && window.innerWidth < 768){
                        shapeSlider.next();
                    }
                },
                slidePrevTransitionStart: function(){
                    if (shapeSlider && window.innerWidth < 768){
                        shapeSlider.prev();
                    }
                }
            },
        });
        
        shapeSlider = new CanvasSlider(shapeSliderParams);

        document.querySelector('.shapeSlideshow-next').addEventListener('click', e => {
            if (window.innerWidth >= 768){
                shapeSlider.next()
            } else {
                shapeSwiper.slideNext()
            }
        });

        document.querySelector('.shapeSlideshow-prev').addEventListener('click', e => {
            if (window.innerWidth >= 768){
                shapeSlider.prev()
            } else {
                shapeSwiper.slidePrev()
            }
        });
    }
}

function individualInit(){
    if (typeof individualitySlider == 'undefined' && (window.scrollY + window.innerHeight >= document.querySelector('#individualitySlideshow').offsetTop)){
        individualitySwiper = new Swiper('.individualitySlideshow', {
            slidesPerView: 1,
            loop: false,
            pagination: {
                el: '.individualitySlideshow-pagination',
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return `<div class="sliderInfo">` +
                            `   <span class="sliderInfo-index ${currentClass}"></span>` +
                            `   <span class="sliderInfo-sep"></span>` +
                            `   <span class="sliderInfo-count ${totalClass}"></span>` +
                            `</div>`;
                },
            },
            breakpoints: {
                768: {
                    simulateTouch: false,
                    shortSwipes: false,
                    longSwipes: false,
                    followFinger: false,
                }
            },
            on: {
                slideNextTransitionStart: function(){
                    if (individualitySlider && window.innerWidth < 768){
                        individualitySlider.next();
                    }
                },
                slidePrevTransitionStart: function(){
                    if (individualitySlider && window.innerWidth < 768){
                        individualitySlider.prev();
                    }
                }
            },
        });
        individualitySlider = new CanvasSlider(individualitySliderParams);

        document.querySelector('.individualitySlideshow-next').addEventListener('click', e => {
            if (window.innerWidth >= 768){
                individualitySlider.next()
            } else {
                individualitySwiper.slideNext()
            }
        });

        document.querySelector('.individualitySlideshow-prev').addEventListener('click', e => {
            if (window.innerWidth >= 768){
                individualitySlider.prev()
            } else {
                individualitySwiper.slidePrev()
            }
        });
    }
}