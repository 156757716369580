import Swiper from 'swiper/swiper-bundle';
import Slide from '@martsis/canvas-slider/src/Slide';
import Pattern from '@martsis/canvas-slider/src/Pattern';
export default (function(){
    document.addEventListener('DOMContentLoaded', () => {
        const bg = new Slide({
            url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2MQFxf/DwAB0wFF/+l+IgAAAABJRU5ErkJggg==',
            hover: true,
            width: 2000,
            height: 2811,
            container: '.colorme-bg',
            pattern: new Pattern({
                width: 400,
                height: 400,
                blur: 70
            })
        });
    })

    window.addEventListener('scroll', e => {
        const title = document.querySelector('.colorme-title');
        const titleY = getCoords(title).top;

        if (!title.classList.contains('on') &&
            (window.scrollY + window.innerHeight / 2 > titleY + 200)
        ){
            colormeImageAnimation(title);
        }
    });

    document.addEventListener('DOMContentLoaded', function(){
        const clides = new Swiper('.colorme-photo2Slider', {
            loop: true,
            slidesPerView: 1,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
        });
    });

    document.addEventListener('DOMContentLoaded', function(){
        const clides = new Swiper('.colorme-text2Slider', {
            loop: true,
            slidesPerView: 1,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
        });
    });

    async function colormeImageAnimation(element){
        element.classList.remove('off');


        setTimeout(() => element.classList.add('on'), frameToTime(20))
        setTimeout(() => element.classList.remove('on'), frameToTime(25))
        setTimeout(() => element.classList.add('on'), frameToTime(27))
        setTimeout(() => element.classList.remove('on'), frameToTime(28))
        setTimeout(() => element.classList.add('on'), frameToTime(29))
        setTimeout(() => element.classList.remove('on'), frameToTime(30))
        setTimeout(() => element.classList.add('on'), frameToTime(31))
    }

    function frameToTime(frame){
        return frame * 1000 / 30;
    }

    function getCoords(elem) {
        let box = elem.getBoundingClientRect();

        return {
          top: box.top + pageYOffset,
          left: box.left + pageXOffset
        };
      }
})();