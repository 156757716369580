import Swiper from 'swiper/swiper-bundle';

export default (function(){
    document.addEventListener('DOMContentLoaded', function() {
        var swiper = new Swiper('.servicesSlideshow', {
            slidesPerView: 1,
            //lazy: true,
            spaceBetween: 10,
            //centeredSlides: true,
            autoHeight: true, 
            pagination: {
                el: '.servicesSlideshow-pagination',
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return `<div class="sliderInfo">` +
                            `   <span class="sliderInfo-index ${currentClass}"></span>` +
                            `   <span class="sliderInfo-sep"></span>` +
                            `   <span class="sliderInfo-count ${totalClass}"></span>` +
                            `</div>`;
                },
            },
            navigation: {
                nextEl: '.servicesSlideshow-next',
                prevEl: '.servicesSlideshow-prev',
            }
        });
    })
})();