import Swiper from "swiper/swiper-bundle";

document.addEventListener('DOMContentLoaded', function(){
    let swiper;

    const slideshowTopElement = document.querySelector('.slideshowTop');

    if (document.body.classList.contains('i-os')){
        slideshowTopElement.style.height = `${window.innerHeight}`;

        window.addEventListener('resize', () => {
            slideshowTopElement.style.height = `${window.innerHeight}`;
        });
    }

    initSlider();

    function initSlider(){
        swiper = new Swiper(slideshowTopElement, {
            slidesPerView: 1,
            loop: true,
            preloadImages: false,
            lazy: true,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.slideshowTop-pagination',
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return `<div class="sliderInfo">` +
                            `   <span class="sliderInfo-index ${currentClass}"></span>` +
                            `   <span class="sliderInfo-sep"></span>` +
                            `   <span class="sliderInfo-count ${totalClass}"></span>` +
                            `</div>`;
                },
            },
            navigation: {
                nextEl: '.slideshowTop-next',
                prevEl: '.slideshowTop-prev',
            },
        });
    }
});