import smoothscroll from "smoothscroll-polyfill";
require("es6-symbol/implement");
import "./polyfill/foreach";
import './osdetect';
import "./form";
import Tabs from './tabs';
import './slider';
import { Menu, MenuCall } from './menu';
import { HeaderCall } from './header';
import './photos';      // gallery
import './services';    // gallery
import './actions';
import './instagram';   // gallery
import './colorme';
import './video';
import { Reviews } from './reviews';     // gallery
import './canvas-sliders';
import innerHeight from 'ios-inner-height';

smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded', function(){
    if (document.querySelector('#brands')) {
        const tabs = new Tabs('#brands');
    }
    if (document.querySelector('#myths-accordion')) {
        const myths = new Tabs('#myths-accordion', true);
    }

    const menu = new Menu({
        container: '.menu',
        buttonClose: '.menu-close',
        buttonOpen: '.menu-button'
    });
    menu.init();

    const reviews = new Reviews({
        slideshowSelector: '.reviews-slideshow',
        reviewsSelector: '.reviews',
        formSelector: '.reviewWrite-main',
        writeCloseSelector: '.reviewWrite-close',
        writeShowSelector: '.reviews-link',
        writeNameSelector: '#review-name',
        writePhoneSelector: '#review-phone',
        writeTextSelector: '#review-text',
        writeRatingsSelector: '.reviewWrite-ratings',
        writeRatingItemSelector: '.reviewRatings-container a',
        writeSendSelector: '.reviewWrite-send',
        neonSelector: '.reviewWrite-neon',
        responseSelector: '.reviewWrite-response'
    });
    reviews.init();

    const menuCallButton = new MenuCall('.menu-call');
    menuCallButton.init();
    const headerCall = new HeaderCall(".header", [
        {
            selector: "#colorme .colorme-inner",
            additionalOffset: { top: 450, bottom: -400 },
        },
        { selector: ".footer", additionalOffset: { top: 0, bottom: 0 } },
    ]);
    headerCall.init();
});