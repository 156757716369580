export class BaseForm{
    constructor(element){
        this.form = element;
        this.sendButton = element.querySelector('.neonForm-send');
        this.inputs = element.querySelectorAll('[data-form]');
        this.url = this.form.getAttribute('action');

        this.sendButton.addEventListener('click', e => {
            e.preventDefault();
            this.send(e);
        });

        this.inputs.forEach(input => {
            input.addEventListener('input', e => {
                this.input(e);
            });

            input.addEventListener('change', e => {
                this.input(e);
            });
        });
    }

    input(event) {
        const emptyRequired = !this.requiredCheck();

        if (this.requiredCheck()){
            this.form.classList.add('on');
            this.form.classList.remove('invalid');
        }
    }

    requiredCheck(){
        for (const input of this.inputs){
            if (input.required && input.value == ""){
                return false;
            }
        }

        return true;
    }

    get params(){
        const newParams = [];

        this.inputs.forEach(input => {
            newParams.push({
                type: input.getAttribute('data-form'),
                value: input.value,
                required: input.required
            });
        });

        return newParams;
    }

    onsend(){
        if (!this.requiredCheck()){
            this.form.classList.remove('on');
            this.form.classList.add('invalid');

            this.inputs.forEach(input => input.classList.add('invalid'))
        } else {
            this.form.classList.remove('invalid');
            this.form.classList.remove('on');
            this.form.classList.add('send');
        }
    }

    async send(event) {
        await this.onsend();

        if (this.requiredCheck()){
            const formData = new FormData();

            this.params.forEach(item => {
                formData.append(item.type, item.value);
            });

            try {
                const response = await fetch(this.url, {
                    method: 'POST',
                    cache: 'no-cache',
                    body: formData
                });

                if (response.status == 200){
                    const json = await response.json();

                    if ('status' in json && json.status != true) {
                        console.table(json);   
                    }
                } else {
                    console.log(`Fetch status: ${response.status}`)
                }
            }
            catch (err){
                console.log(err);
            }
        }
    }
}

export class NeonForm extends BaseForm {
    constructor(element, sendAnimationParams){
        super(element)

        this.sendAnimationParams = sendAnimationParams;

        window.addEventListener('scroll', e => {
            
           this.onscroll(e);
        });
    }

    sendAnimation(){
        this.sendAnimationParams.forEach((item, index, arr) => {
            const elements = document.querySelectorAll(item.selector);

            elements.forEach(element => {
                setTimeout(() => {
                    Object.keys( item.styles ).forEach(style => element.style[style] = item.styles[style]);
                }, item.delay);
            })
        });
    }

    async onsend() {
        if (!this.requiredCheck()){
            this.form.classList.remove('on');
            this.form.classList.add('invalid');
        } else {
            this.sendAnimation();
            this.form.classList.remove('invalid');
            this.form.classList.remove('on');
            this.form.classList.add('send');
        }
    }

    async onscroll(){
        if (!this.form.classList.contains('on') &&
            !this.form.classList.contains('invalid') &&
            !this.form.classList.contains('send') &&
            (window.scrollY + window.innerHeight / 2 > this.form.offsetTop)
        ){
            this.form.classList.add('on');
        }
    }
}