export class HeaderCall {
    constructor(selector, darkElemen = [{}]) {
        this.selector = selector;
        this.darkElementsOffsets = [];
        this.darkElements = [];
        darkElemen.forEach((element) => {
            const htmlElem = document.querySelector(element.selector);
            this.darkElements.push({
                elem: htmlElem,
                bottom: htmlElem.offsetTop + htmlElem.offsetHeight,
                additionalOffset: element.additionalOffset,
            });
        });
    }

    init() {
        this._header = document.querySelector(this.selector);
        this.headerOffset =
            this._header.offsetTop + this._header.offsetHeight / 2;
        window.addEventListener("scroll", (e) => {
            const headerOffset = window.innerHeight - this.headerOffset;
            let flag = false;
            this.darkElements.forEach((darkElement) => {
                const coords = darkElement.elem.getBoundingClientRect(),
                    headerVsElemDist =
                        coords.y +
                        darkElement.additionalOffset.top -
                        this.headerOffset;
                if (
                    headerVsElemDist <= 0 &&
                    Math.abs(headerVsElemDist) <=
                        coords.height + darkElement.additionalOffset.bottom
                ) {
                    flag = true;
                }
            });
            if (flag) {
                this._header.classList.remove("black");
                return;
            }
            if (window.scrollY > headerOffset) {
                this._header.classList.add("black");
            } else {
                this._header.classList.remove("black");
            }
        });
    }
}
