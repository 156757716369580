import Swiper from 'swiper/swiper-bundle';

class Write{
    constructor(){
        this.name = '';
        this.phone = '';
        this.text = '';
        this.ratings = 0;
        this.shown = false;
    }

    clear(){
        this.name = null;
        this.phone = null;
        this.text = null;
        this.ratings = 0;
    }

    close(){
        this.shown = false;
    }

    show(){
        this.shown = true;
    }

    setRating(num){
        if (typeof num == 'number'){
            if (num >= 0 && num <= 5) {
                this.ratings = num;
            } else {
                this.ratings = 0;
            }
        } else {
            this.ratings = 0;
        }
    }
}

class Selectors{
    constructor(){
        this.reviews = '';
        this.slideshow = '';
        this.writeShow = '';
        this.writeClose = '';
        this.writeName = '';
        this.writePhone = '';
        this.writeText = '';
        this.writeRatings = '';
        this.writeRatingItem = '';
        this.writeSend = '';
        this.neon = '';
        this.form = '';
        this.response = '';
    }
}

export class Reviews{
    constructor({
        slideshowSelector,
        writeShowSelector,
        writeCloseSelector,
        reviewsSelector,
        writeNameSelector,
        writePhoneSelector,
        writeTextSelector,
        writeRatingsSelector,
        writeRatingItemSelector,
        writeSendSelector,
        neonSelector,
        formSelector,
        responseSelector
    }){
        this.write = new Write();
        this.selectors = new Selectors();
        this.selectors.reviews      = reviewsSelector;
        this.selectors.slideshow    = slideshowSelector;
        this.selectors.writeShow    = writeShowSelector;
        this.selectors.writeClose   = writeCloseSelector;
        this.selectors.writeName    = writeNameSelector;
        this.selectors.writePhone   = writePhoneSelector;
        this.selectors.writeText    = writeTextSelector;
        this.selectors.writeRatings = writeRatingsSelector;
        this.selectors.writeRatingItem  = writeRatingItemSelector;
        this.selectors.writeSend    = writeSendSelector;
        this.selectors.neon         = neonSelector;
        this.selectors.form         = formSelector;
        this.selectors.response     = responseSelector;
        this.formState = 'ok';
    }

    init(){
        this._slideshowElement  = document.querySelector(this.selectors.slideshow);
        this._reviewsElement    = document.querySelector(this.selectors.reviews);
        this._neonElement       = document.querySelector(this.selectors.neon);
        this._formElement       = document.querySelector(this.selectors.form);
        this._responseElement   = document.querySelector(this.selectors.response);
        this._writeRatingsElement = document.querySelector(this.selectors.writeRatings);
        this._writeRatingItems  = document.querySelectorAll(this.selectors.writeRatingItem);
        
        this._writeShowButton   = document.querySelector(this.selectors.writeShow);
        this._writeCloseButton  = document.querySelector(this.selectors.writeClose);
        this._writeSendButton   = document.querySelector(this.selectors.writeSend);
        
        this._writeNameInput    = document.querySelector(this.selectors.writeName);
        this._writePhoneInput   = document.querySelector(this.selectors.writePhone);
        this._writeTextInput    = document.querySelector(this.selectors.writeText);

        this._formActionUrl     = this._formElement.getAttribute('action');

        this._writeShowButton.addEventListener('click', e => {
            e.preventDefault();
            this.writeShow();
        });

        this._writeCloseButton.addEventListener('click', e => {
            e.preventDefault()
            this.writeClose();
        });

        this._writeNameInput.addEventListener('input', e => {
            this.write.name = e.target.value;
            this.update();
        });

        this._writePhoneInput.addEventListener('input', e => {
            this.write.phone = e.target.value;
            this.update();
        });

        this._writeTextInput.addEventListener('input', e => {
            this.write.text = e.target.value;
            this.update();
        });

        this._writeRatingItems.forEach(item => {
            item.addEventListener('click', e => {
                e.preventDefault();

                const value = parseInt(e.target.getAttribute('data-rating'));

                if (typeof value == 'number' && isFinite(value)){
                    this.write.ratings = value;
                }

                this.update();
            });
        });

        this._writeSendButton.addEventListener('click', e => {
            e.preventDefault();

            this.writeSend();
        });
        
        this.update();
    }
    
    async writeSend(){
        if (this.formState == 'send') return;
        
        if (this.write.ratings <= 0) {
            this.write.ratings = -1;
        }
        
        if (this.formStateCheck() == 'ok'){
            const data = new FormData();
            data.append('name', this.write.name);
            data.append('phone', this.write.phone);
            data.append('text', this.write.text);
            data.append('ratings', this.write.ratings);

            try {
                const response = await fetch(this._formActionUrl, {
                    method: 'POST',
                    body: data,
                    cache: 'no-cache',
                });

                const json = await response.json();

                if ('status' in json && json.status){
                    this.formState = 'send';
                } else {
                    this.formState = 'invalid';
                }
            }
            catch(err){
                this.formState = 'invalid';
            }
        }
    
        this.update()
    }

    writeShow(){
        this.write.show();

        this.update();
    }

    writeClose(){
        this.write.clear();
        this.write.close();
        this.formState = 'ok';

        this.update();
    }

    formStateCheck(){
        if (this.write.ratings <= 0 || 
            (typeof this.write.name == 'string' && this.write.name == '') || 
            (typeof this.write.phone == 'string' && this.write.phone == '') || 
            (typeof this.write.text == 'string' && this.write.text == '')
        ) {
            this.formState = 'invalid';
        } else {
            this.formState = 'ok';
        }

        return this.formState;
    }

    update(){
        if (this.write.shown){
            this._reviewsElement.classList.add('new');
        } else {
            this._reviewsElement.classList.remove('new');
        }

        if (typeof this.write.name == 'string' && this.write.name != ''){
            this._writeNameInput.value = this.write.name;
        } else {
            if (this.formState == "invalid"){
                this._writeNameInput.classList.add('invalid');
            }
            
            this._writeNameInput.value = '';
        }

        if (typeof this.write.phone == 'string' && this.write.phone != ''){
            this._writePhoneInput.value = this.write.phone;
        } else {
            if (this.formState == "invalid"){
                this._writePhoneInput.classList.add('invalid');
            }

            this._writePhoneInput.value = '';
        }

        if (typeof this.write.text == 'string' && this.write.text != ''){
            this._writeTextInput.value = this.write.text;
        } else {
            if (this.formState == "invalid"){
                this._writeTextInput.classList.add('invalid');
            }

            this._writeTextInput.value = '';
        }

        if (typeof this.write.ratings == 'number'){
            for (let i = 0; i <= 5; i++){
                this._writeRatingsElement.classList.remove(`rating-${i}`);    
            }

            if (this.write.ratings >= 0) {
                this._writeRatingsElement.classList.add(`rating-${this.write.ratings}`);
                this._writeRatingsElement.classList.remove('require');
            } else {
                this._writeRatingsElement.classList.add('require');
            }
        }

        switch(this.formState){
            case "invalid":
                this._neonElement.classList.remove('send');
                this._neonElement.classList.add('invalid');
                this._formElement.classList.remove('hide');
                this._responseElement.classList.remove('show');
                break;

            case "send":
                this._neonElement.classList.add('send');
                this._neonElement.classList.remove('invalid');
                this._formElement.classList.add('hide');
                this._responseElement.classList.add('show');
                break;

            case "ok":
            default:
                this._neonElement.classList.remove('send');
                this._neonElement.classList.remove('invalid');
                this._formElement.classList.remove('hide');
                this._responseElement.classList.remove('show');
                break;
        }
    }
}

export const reviewsSlider = (function(){
    function init(){
        const reviewsSlideshow = document.querySelector('.reviewsSlideshow');
        
        const reviewWriteShow = document.querySelector('.reviews-link');

        
        const swiper = new Swiper(reviewsSlideshow, {
            slidesPerView: 1,
            spaceBetween: 10,
            pagination: {
                el: '.reviewsSlideshow-pagination',
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return `<div class="sliderInfo">` +
                            `   <span class="sliderInfo-index ${currentClass}"></span>` +
                            `   <span class="sliderInfo-sep"></span>` +
                            `   <span class="sliderInfo-count ${totalClass}"></span>` +
                            `</div>`;
                },
            },
            navigation: {
                nextEl: '.reviewsSlideshow-next',
                prevEl: '.reviewsSlideshow-prev',
            },
            breakpoints: {
                576: {
                    spaceBetween: 10,
                },
                768: {
                    spaceBetween: 10,
                },
                992: {
                    spaceBetween: 40,
                }
            }
        });
    }

    document.addEventListener('DOMContentLoaded', function() {
        init();
    });
})();