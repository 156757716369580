import Swiper from "swiper/swiper-bundle";

document.addEventListener('DOMContentLoaded', function () {
    const videoSlide = new Swiper('.js-video', {
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
            el: '.video__pagination',
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return `<div class="sliderInfo">` +
                    `   <span class="sliderInfo-index ${currentClass}"></span>` +
                    `   <span class="sliderInfo-sep"></span>` +
                    `   <span class="sliderInfo-count ${totalClass}"></span>` +
                    `</div>`;
            },
        },
        navigation: {
            nextEl: '.video__navigation .video__btn-next',
            prevEl: '.video__navigation .video__btn-prev',
        },
        breakpoints: {
            767: {
                slidesPerView: 'auto',
                spaceBetween: 40,
            }
        }
    });
    document.addEventListener('click', (e) => {
        if (e.target.classList.contains('js-video-btn')) {
            document.querySelector('.js-video-modal').classList.add('active');
            document.querySelector('body').style.overflow = "hidden";
            document.querySelector('.js-video-modal iframe').setAttribute('src', e.target.dataset.url);
        }
    });
    document.querySelector('.js-video-modal').addEventListener('click', (e) => {
        if (e.target.classList.contains('js-video-modal') || !e.target.classList.contains('.js-modal-close')) {
            document.querySelector('.js-video-modal').classList.remove('active');
            document.querySelector('body').style.overflow = "auto";
            document.querySelector('.js-video-modal iframe').setAttribute('src', '');
        }
    });
});