import Swiper from 'swiper/swiper-bundle';

export default (function(){
    document.addEventListener('DOMContentLoaded', function() {
        const instagramSlideshowElement = document.querySelector('.instagramSlideshow');
        let swiper;
        window.addEventListener('scroll', () => {
            if (instagramSlideshowElement && typeof swiper == 'undefined' && (window.scrollY + window.innerHeight > instagramSlideshowElement.offsetTop)){
                swiper = new Swiper('.instagramSlideshow', {
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                    lazy: true,
                    preloadImages: false,
                    watchSlidesVisibility: 3,
                    pagination: {
                        el: '.instagramSlideshow-pagination',
                        type: 'fraction',
                        renderFraction: function (currentClass, totalClass) {
                            return `<div class="sliderInfo">` +
                                    `   <span class="sliderInfo-index ${currentClass}"></span>` +
                                    `   <span class="sliderInfo-sep"></span>` +
                                    `   <span class="sliderInfo-count ${totalClass}"></span>` +
                                    `</div>`;
                        },
                    },
                    navigation: {
                        nextEl: '.instagramSlideshow-next',
                        prevEl: '.instagramSlideshow-prev',
                    },
                    breakpoints: {
                        576: {
                            spaceBetween: 10,
                        },
                        768: {
                            spaceBetween: 10,
                        },
                        992: {
                            spaceBetween: 40,
                        }
                    }
                });
            }
        });
    });
})();