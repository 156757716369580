import Swiper from 'swiper/swiper-bundle';

export default (function(){
    let swiper;
    let bg;

    function initPhotos(){
        if (document.querySelector('.cmPhoto-bg')) {
            if (typeof bg == 'undefined' && (window.scrollY + window.innerHeight > document.querySelector('.cmPhoto-bg').offsetTop)){
                bg = document.querySelector('.cmPhoto-bg').classList.add('bg-waves');
            }
        }

        if (document.querySelector('.cmPhotoSlideshow')) {
            if (typeof swiper == 'undefined' && (window.scrollY + window.innerHeight > document.querySelector('.cmPhotoSlideshow').offsetTop)){
                swiper = new Swiper('.cmPhotoSlideshow', {
                    slidesPerView: 'auto',
                    lazy: true,
                    preloadImages: false,
                    loop: true,
                    spaceBetween: 10,
                    watchSlidesVisibility: 3,
                    pagination: {
                        el: '.cmPhotoSlideshow-pagination',
                        type: 'fraction',
                        renderFraction: function (currentClass, totalClass) {
                            return `<div class="sliderInfo">` +
                                    `   <span class="sliderInfo-index ${currentClass}"></span>` +
                                    `   <span class="sliderInfo-sep"></span>` +
                                    `   <span class="sliderInfo-count ${totalClass}"></span>` +
                                    `</div>`;
                        },
                    },
                    navigation: {
                        nextEl: '.cmPhotoSlideshow-next',
                        prevEl: '.cmPhotoSlideshow-prev',
                    },
                    breakpoints: {
                        576: {
                            spaceBetween: 10,
                        },
                        768: {
                            spaceBetween: 10,
                        },
                        992: {
                            spaceBetween: 40,
                        }
                    }
                });
            }
        }
    }

    document.addEventListener('DOMContentLoaded', function() {


        initPhotos();

        window.addEventListener('scroll', () => {
            initPhotos();
        });
    })
})();